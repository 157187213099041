<template>
 <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
     <div class="container-fluid">

      <div class="row">
        <div class="col-md-12">

         <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Ara:
                    <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                    </b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table :items="transactionData" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">

                <template v-slot:cell(action)="data">
                  <b-dropdown bottom variant="info">
                                   <template #button-content>
                                         <i class="el-icon-more"></i>
                                    </template>
                                  <template>
                                        <b-dropdown-item  id="toggle-btn"  v-b-modal.modal-1 @click="Edit(data.item)">
                                            <i class="el-icon-edit"></i> Duzenle
                                        </b-dropdown-item>
                                        <b-dropdown-item id="toggle-btn" @click="Goster(data.item.lisanskey)">
                                            <i class="el-icon-link"></i> Lisans
                                        </b-dropdown-item>
                                        <b-dropdown-item id="toggle-btn" @click="Email(data.item)">
                                            <i class="el-icon-link"></i> E-mail Gönder
                                        </b-dropdown-item>
                                          <b-dropdown-item  id="toggle-btn"  v-b-modal.modal-2 @click="DetayliBilgi(data.item)">
                                              <i class="el-icon-edit"></i> Detaylı Bilgiler
                                          </b-dropdown-item>
                                          <b-dropdown-item  @click="LogList(data.item)">
                                            <i class="el-icon-s-order"></i> Log Listele
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="Delete(data.item)">
                                          <i class="el-icon-delete"></i> Sil
                                      </b-dropdown-item>
                                      
                                  </template>
                                
                            
                        </b-dropdown>
                </template>
                <template v-slot:cell(fatura)="data">
                    <b-alert show v-if="data.item.demosatis=='Satış' && data.item.fatura==false" style="background-color:#fa87d4;color:white">Fatura Kesilmemiş</b-alert>
                    <b-alert show v-else-if="data.item.fatura==true">Fatura Kesilmiş</b-alert>
                    
                    <b-alert show v-else-if="data.item.fatura==false && data.item.ilksatis==0" style="background-color:orange;color:white">Demo</b-alert>
                    <b-alert show v-else-if="data.item.fatura==false && data.item.ilksatis==1" variant="success">Fatura Kesilmemiş-İlk Satış</b-alert>
                    

                </template>
                  <template v-slot:cell(demosatis)="data">

                      <span>{{data.item.demosatis}}</span>

                  </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

          <b-modal id="modal-1" v-if="modaldata!=null"  title="Müşteri Düzenle" hide-footer>
            <div class="row">
              <div class="col-md-6">
                  <b-form-input id="input-small" size="sm" v-model="modaldata.firmaadi" placeholder="Müşteri"></b-form-input>
                  <br>
                  <b-form-input id="input-small" size="sm" v-model="modaldata.email" placeholder="E-mail"></b-form-input>
                  <br>
                  <b-form-input id="input-small" size="sm" v-model="modaldata.kullanicisiniri" placeholder="Kullanıcı Sınırı"></b-form-input>
                  <br>
                  <b-form-input id="input-small" size="sm" v-model="modaldata.cihazsiniri" placeholder="Cihaz Sınırı"></b-form-input>
                  <br>
                  <b-form-input id="input-small" size="sm" v-model="modaldata.bayi" placeholder="Bayi"></b-form-input>
                  <br>
                  
                  <b-form-select v-model="modaldata.dist" :options="distoptions"></b-form-select>
                  <br>
                  <br>
                  <b-form-group label="" v-slot="{ ariaDescribedby2 }">
                         <b-form-radio-group
                                 v-model="modaldata.status"
                                 v-if="yetki=='admin'"
                                 :options="statusoptions"
                                 :aria-describedby="ariaDescribedby2"
                                 name="radio-inline2"
                         ></b-form-radio-group>
                     </b-form-group>
                     <br>
                  <b-form-group label="" v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                              v-model="modaldata.demosatis"
                              :options="demosatisoptions"
                              :aria-describedby="ariaDescribedby"
                              name="radio-inline"
                      ></b-form-radio-group>
                  </b-form-group>
                  <br>
                  <b-form-checkbox
                          id="checkbox-1"
                          v-model="modaldata.ilksatis"
                          name="checkbox-1"
                          value="1"
                          unchecked-value="0"
                  >
                      İlk Satış
                  </b-form-checkbox>

              </div>
              <div class="col-md-6">
                 Fatura Durumu: <b-form-checkbox v-model="modaldata.fatura" name="check-button" @change="AktifPasifFatura(modaldata)" switch></b-form-checkbox>
                  <br>
                <b-form-input id="input-small" size="sm" v-model="modaldata.adres" placeholder="Adres"></b-form-input>
                <br>
                <b-form-input id="input-small" size="sm" v-model="modaldata.telefon" placeholder="Telefon"></b-form-input>
                <br>
                <b-form-datepicker id="example-datepicker" v-model="modaldata.tarih" placeholder="Lisans Bitiş" size="sm" class="mb-2"></b-form-datepicker>
                <br>
                  <b-form-textarea
                          id="textarea"
                          v-model="modaldata.aciklama"
                          placeholder="Açıklama"
                          rows="3"
                          max-rows="6"
                  ></b-form-textarea>
                  <br>
                 <b-button variant="outline-primary" @click="UpdateMusteri(modaldata)">Güncelle</b-button>
              </div>
            </div>
          </b-modal>


            <b-modal size="lg" id="modal-2"  title="Detaylı Lisans Bilgileri" hide-footer> <p class="mt-2" @click="copyText"> <b-icon font-scale="2" icon="file-earmark" aria-hidden="true"></b-icon>Kopyala</p>
              <span  ref="copyData">
 <table style="border:1px dashed;">
                  <tbody><tr>
                    <td style="background-color: #2c9cf2;
                    color: white;
                    padding: 14px !important;
                    margin: 0px;">Son Kullanıcı</td>
                    <td style="background-color: #dadfe3;
                    padding: 14px !important;">{{items3[0].Son_Kullanıcı}}</td>
                  </tr>
                  <tr>
                    <td style="background-color: #2c9cf2;
                    color: white;
                    padding: 14px !important;
                    margin: 0px;">Email</td>
                    <td style="background-color: #dadfe3;
                    padding: 14px !important;">{{items3[0].Email}}</td>
                  </tr>
                  <tr>
                      <td style="background-color: #2c9cf2;
                      color: white;
                      padding: 14px  !important;
                      margin: 0px;">Lisans Key</td>
                      <td style="background-color: #dadfe3;
                      padding: 14px !important;font-size:10px;">{{items3[0].Lisans_Key}}</td>
                  </tr>
                  <tr>
                      <td style="background-color: #2c9cf2;
                      color: white;
                      padding: 14px !important;
                      margin: 0px;">Kurulum Dokümanı</td>
                      <td style="background-color: #dadfe3;
                      padding: 14px !important;">http://download.subgatenetwork.com/pdf/</td>
                  </tr>
                  <tr>
                      <td style="background-color: #2c9cf2;
                      color: white;
                      padding: 14px !important;
                      margin: 0px;">Kurulum Dosyasi</td>
                      <td style="background-color: #dadfe3;
                      padding: 14px !important;">{{items3[0].Kurulum_Dosyasi}}</td>
                  </tr>
                </tbody>
              </table>
              </span>
                   
                
            </b-modal>


            <el-dialog
              title="Firma Sistem Logları"
              :visible.sync="centerDialogVisible"
              width="50%"
              center>
              <b-pagination
                      v-model="logcurrentPage"
                      :total-rows="logtotalRows"
                      :per-page="logperPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                    <b-table
                      :items="logitems"
                      :fields="logfields"
                      :current-page="logcurrentPage"
                      :per-page="logperPage"
                      :sort-by.sync="logsortBy"
                      :sort-desc.sync="logsortDesc"
                      :sort-direction="logsortDirection"
                      stacked="md"
                      show-empty
                      small
                    >
                    </b-table>
            </el-dialog>
            
        </div>
      </div>
     </div>
     
 </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {axiosInstance} from '../../../helpers/axios';
import moment from 'moment';
import Swal from 'sweetalert2'
export default {
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "Kayıtlı Müşterilerimiz",
        items: [{
            text: "Anasayfa"
          },
          {
            text: "Müşteriler",
            active: true
          }
        ],
        transactionData: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        pageOptions: [5, 10, 15, 25, 50],
        filter: null,
        filterOn: [],
        sortBy: "orderid",
        sortDesc: false,
        fields: [{
            key: "action",
            label: "İşlemler"
          },{
            key: "fatura",
            sortable: true,
            label: "Fatura Durum"
        },{
            key: "uuid",
            sortable: true,
            label: "Uuid"
        },{
            key: "ipadres",
            sortable: true,
            label: "External İp Adres"
        },{
            key: "demosatis",
            sortable: true,
            label: "Demo / Satis"
        },{
            key: "email",
            sortable: true,
            label: "E-mail"
          },
          {
            key: "kullanicisiniri",
            sortable: true,
            label: "Kullanıcı Sınırı"
          },
          {
            key: "cihazsiniri",
            sortable: true,
            label: "Cihaz Adeti"
          },
          {
            key: "adres",
            sortable: true,
            label: "Adres"
          },
          {
            key: "telefon",
            sortable: true,
            label: "Telefon"
          },
          {
            key: "bitistarih",
            sortable: true,
            label: "Lisans Bitiş"
          },
          {
            key: "bayi",
            sortable: true,
            label: "Bayi"
          },
          {
            key: "distadi",
            sortable: true,
            label: "Dist"
          },
            {
                key: "aciklama",
                sortable: true,
                label: "Açıklama"
            },
            {
                key: "version",
                sortable: true,
                label: "Version"
            }
        ],
        modaldata:null,
          demosatisoptions:[
              { text: 'Demo', value: 'Demo' },
              { text: 'Satış', value: 'Satış' },
          ],
          statusoptions:[
                 { text: 'Pasif', value: "pasif" },
                 { text: 'Aktif', value: "aktif" },
             ],
          items3: [
              {
                  Son_Kullanıcı:'',
                  Email: '',
                  Lisans_Key: '',
                  Kurulum_Dosyasi: '',
              }
          ],
          tableCopy:'',
          distoptions:[],
          centerDialogVisible:false,
          logitems: [],
          logfields:[
            { key: 'uuid', label: 'Donanım İd', sortable: true, sortDirection: 'desc' },
            { key: 'ipadres', label: 'İp Adres', sortable: true, sortDirection: 'desc' },
            { key: 'tarih', label: 'Tarih', sortable: true, sortDirection: 'desc' },
            { key: 'durum', label: 'Aktif/Pasif', sortable: true, sortDirection: 'desc' },
          ],
          logtotalRows: 1,
          logcurrentPage: 1,
          logperPage: 5,
          logsortBy: '',
          logsortDesc: false,
          logsortDirection: 'asc',
              
      }
    },
    computed: {
      rows() {
        return this.transactionData.length;
      },
      yetki()
         {
           return this.$store.getters["auth/rl"];
         },
      logsortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }   
    },
    created() {
      var vm = this;
      vm.List();
      vm.dist_list();
    },
    methods: {
      copyText() {
        var vm = this;
        //navigator.clipboard.writeText(this.$refs.copyData)
        const alan = this.$refs.copyData;
        const range = document.createRange();
        range.selectNode(alan);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        try {
          // Kopyalamak için gerekli seçim ve kopyalama işlemi
          document.execCommand("copy"); // Düz metin içeriği
          navigator.clipboard.writeText("<p>Merhabalar</p></br><p>Lisans bilgileriniz aşağıdaki gibidir,</p></br><p>İyi Çalışmalar</p></br> "+alan.innerHTML)
        } catch (err) {
          console.error("Kopyalama işlemi başarısız oldu:", err);
        }

        // Seçimi temizleme
        selection.removeAllRanges();
        vm.$bvToast.toast("Veri Kopyalandı", {
            title: `Uyari !`,
            variant: "success",
            solid: true
        });
        
      },
      dist_list()
        {
            var vm = this;
            try {

                axiosInstance.post("dist/list").then((response)=>{

                     if(response.data.error==true)
                        {
                        vm.$bvToast.toast(response.data.message, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                        }else{
                            response.data.data.forEach(element => {
                                vm.distoptions.push({
                                  value:element.id,
                                  text:element.adi,
                                })
                            });
                        }

                }).catch((error)=>{

                    vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                    
                })
                
            } catch (error) {

                vm.$bvToast.toast(error, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                
            }
      },
      DetayliBilgi(params)
      {
         var vm = this;
         vm.items3[0].Son_Kullanıcı = params.firmaadi;
         vm.items3[0].Email = params.email;
         vm.items3[0].Lisans_Key = params.lisanskey;
         vm.items3[0].Kurulum_Dosyasi = "http://download.subgatenetwork.com/SubgateNetworkV3.exe";


      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      Email(params)
      {
        var vm = this;

        try {

          axiosInstance.post("kullanicilar/satis/emailgonder",{
            param:params,
          }).then((response)=>{

            

            if(response.data.error==true)
            {
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            }else{
              vm.$bvToast.toast("E-mail bilgilendirme yapildi", {
                title: `Bildirim !`,
                variant: "info",
                solid: true
              });
            }
          
          }).catch((error)=>{
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          })
          
        } catch (error) {

          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          
        }
      },
      Goster(params)
      {
        Swal.fire({
          icon: 'success',
          title: 'Lisans Key',
          text: params,
          showCancelButton: false,
          showConfirmButton: false
        })
      },
      UpdateMusteri(params)
      {
        var vm = this;
        try {

          

          axiosInstance.post("kullanicilar/satis/guncelle",{
           params:params
          }).then((response)=>{

            if(response.data.error==true)
            {
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            }else{
              vm.$bvToast.toast("Güncelleme başarılı,Lisans Güncellendi", {
                title: `Bildirim !`,
                variant: "info",
                solid: true
              });
              vm.List();
            }
          
          
          }).catch(error=>{
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          })

          
        } catch (error) {
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
        }
      },
      AktifPasif(params,params2)
      {
         var vm = this;
         try {

          axiosInstance.post("license/passiveaktivechange",{
           aktifpasif:params,
           lisansid:params2

          }).then((response)=>{

           if(response.data.error==true)
           {
             vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
               title: `Bildirim !`,
               variant: "warning",
               solid: true
             });
           }else{

             vm.$bvToast.toast(response.data.message, {
               title: `Bildirim !`,
               variant: "warning",
               solid: true
             });

           }

          }).catch((error)=>{
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          });
          
         } catch (error) {
           vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
         }
      },
      AktifPasifFatura(params)
        {
            var vm = this;
            try {

                axiosInstance.post("license/faturapassiveaktivechange",{
                    veriler:params
                }).then((response)=>{

                    if(response.data.error==true)
                    {
                        vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                            title: `Bildirim !`,
                            variant: "warning",
                            solid: true
                        });
                    }else{

                        vm.$bvToast.toast(response.data.message, {
                            title: `Bildirim !`,
                            variant: "info",
                            solid: true
                        });

                    }

                }).catch((error)=>{
                    vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                        title: `Uyari !`,
                        variant: "warning",
                        solid: true
                    });
                });

            } catch (error) {
                vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                    title: `Uyari !`,
                    variant: "warning",
                    solid: true
                });
            }
      },
      List()
      {
         var vm = this;
         vm.transactionData = [];
         try {

          axiosInstance.get("kullanicilar/satis/listele").then((response)=>{

           if (response.data.error == true) {
              vm.$bvToast.toast(response.data.message, {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
           }else{
              if(response.data.data.length > 0)
              {
                 response.data.data.forEach(element => {
                    vm.transactionData.push({
                     bayi:element.bayi,
                     dist:element.dist,
                     version:element.version,
                     adres:element.adres,
                     distadi:element.DistAdi,
                     aktif:(element.aktif==1) ? true : false,
                     bitistarih: element.bitistarih,
                     email: element.email,
                     firmaadi:element.firmaadi, 
                     id:element.id,
                     kullanicisiniri:element.kullanicisiniri,
                     cihazsiniri:element.cihazsiniri,
                     lisansid:element.lisansid, 
                     lisanskey:element.lisanskey, 
                     telefon:element.telefon,
                     yetkili:element.yetkili,
                     fatura:(element.fatura==1) ? true  : false,
                     demosatis:element.demosatis,
                     aciklama:element.aciklama,
                     ilksatis:element.ilksatis,
                     status:element.status,
                     uuid:(element.eskilisans_uuid) ? element.eskilisans_uuid : (element.yeniuuid) ? element.yeniuuid : null,
                     ipadres:(element.eskiexternal_ip) ? element.eskiexternal_ip : (element.yeniipadres) ? element.yeniipadres : null,
                    })
                 });

              }

              
           }

           
          }).catch((error)=>{

            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
            
          })
          
         } catch (error) {

          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });

         }
      },
      Edit(row) {
        this.modaldata = row;
        this.modaldata["tarih"] = moment(row.bitistarih).format("YYYY-MM-DD")
      },
      Delete(params) {
        var vm = this;
        try {

            if(confirm("Silmeyi Onaylıyor musunuz ?")) {
                axiosInstance.post("kullanicilar/satis/sil",{
                    params:params
                }).then((response)=>{

                    if(response.data.error==true)
                    {
                        vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
                        });
                    }else{
                        vm.$bvToast.toast("Lisans Silinmiştir", {
                            title: `Bildirim !`,
                            variant: "info",
                            solid: true
                        });
                        vm.List();
                    }


                }).catch((error)=>{
                    vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
                        title: `Uyari !`,
                        variant: "warning",
                        solid: true
                    });
                })
            }


          
        } catch (error) {
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
        }
      },
      LogList(params)
      {
        var vm = this;
        vm.centerDialogVisible= true;
        vm.logitems=[];
        try {

          axiosInstance.post("kullanicilar/log/list",{
           params:params
          }).then((response)=>{

            if(response.data.error==true)
            {
              vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz ", {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            }else{
              if(response.data.data.length>0)
              {
                response.data.data.forEach(element => {
                  vm.logitems.push({
                    uuid:element.uuid,
                    ipadres:element.ipadres,
                    tarih:moment(element.tarih).format("YYYY-MM-DD HH:mm:ss"),
                    durum:(element.durum==1) ? "Aktif" : "Pasif"
                  })
                });

                vm.totalRows = response.data.data.length;
              }
            }


          }).catch((error)=>{
            vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          })
          
        } catch (error) {
          vm.$bvToast.toast("Hata oluştu.Lütfen daha sonra tekrar deneyiniz " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
        }
      }
    },
}
</script>
<style lang="scss">
.modal-content{
  display: table-cell !important;
}
.swal-wide{
    width:850px !important;
}
    #modal-2___BV_modal_body_{
        padding:0px !important;
        table {
            tbody{
                tr{

                    td{
                        padding:0px !important;
                        &:before{
                            height: 50px !important;
                            background-color: #3385ff;
                            text-align: left !important;
                            padding-left:25px;
                            padding-top:10px;
                            color:white;
                        }
                    }

                    td[data-label="Lisans Key"]
                    {
                        div{
                            overflow: scroll;
                            width: 400px;
                            height: 43px;
                        }
                    }



                }
            }

        }
    }
</style>